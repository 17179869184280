module.exports = [{
      plugin: require('../../../../node_modules/.pnpm/gatsby-plugin-image@1.14.2_twhxuvrz753hmxyepeq5pni244/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/.pnpm/gatsby-plugin-mdx@2.14.1_opunafekjrohdtvwuav3zzk4qa/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"remarkPlugins":[null],"rehypePlugins":[null],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"showCaptions":true,"backgroundColor":"transparent","disableBgImageOnAlpha":true}},"gatsby-remark-autolink-headers","gatsby-remark-copy-linked-files","gatsby-remark-graphviz"],"defaultLayouts":{},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/buildhome/repo/packages/blog/gatsby-blog-src","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../../../../node_modules/.pnpm/gatsby-plugin-manifest@3.14.0_gatsby@3.14.6/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"随便写写","short_name":"随便写写","description":"随便写写, 全而不精","lang":"zh-CN","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"assets/favicon.png","theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ffb2f5920276563941e386260c477317"},
    },{
      plugin: require('../../gatsby-theme-plain/gatsby-browser.js'),
      options: {"plugins":[],"siteMetadata":{"title":"随便写写","description":"随便写写, 全而不精","siteUrl":"https://blog.acdzh.com","author":{"name":"acdzh"},"favicon":"assets/favicon.png","social":{"github":"acdzh","twitter":"acdzh","steam":"acdzh","mail":"acdzh@outlook.com"},"postsPerPage":8}},
    },{
      plugin: require('../../../../node_modules/.pnpm/gatsby-plugin-google-analytics@3.14.0_gatsby@3.14.6/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-148601605-2","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../../../../node_modules/.pnpm/gatsby-plugin-valine@1.0.2_gatsby@3.14.6/node_modules/gatsby-plugin-valine/gatsby-browser.js'),
      options: {"plugins":[],"appId":"THSEEUpmt73ioQLUO7dO6sI8-9Nh9j0Va","appKey":"2ko493HrmEBiJ5FFTpPnnrWg","avatar":"robohash","visitor":true,"recordIP":true,"enableQQ":true},
    }]
